const Facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clip-path="url(#clip0_1162_1796)">
        <circle cx="16" cy="16" r="15.5" stroke="#EBEBEB" />
        <path
          d="M17.7682 9.37955L17.8895 9.51505L17.7498 9.39684L17.7481 9.39851L18.0351 9.70171L17.6962 9.46017C17.3618 9.92932 17.2018 10.5003 17.2434 11.0745V13.3204V13.8204H17.7434H20.6977L20.3621 16.3787H17.7484H17.2484V16.8787V25.5004H14.5675V16.8779V16.3779H14.0675H11.5009V13.8204H14.0675H14.5675V13.3204V10.6996V10.6838L14.5665 10.6681C14.531 10.1034 14.6117 9.53746 14.8038 9.00525C14.9941 8.47802 15.2896 7.99505 15.6722 7.58567C16.0645 7.21694 16.527 6.93083 17.0321 6.7444C17.5423 6.55613 18.0854 6.47345 18.6285 6.5014L18.6413 6.2526L18.6434 6.50206H18.6542V6.50195C19.3085 6.49124 19.9628 6.51331 20.6146 6.56802L20.6137 6.56982L20.885 6.59765V8.81789H19.5273C19.2169 8.78292 18.9024 8.81274 18.6038 8.90561C18.2945 9.00183 18.0095 9.16346 17.7682 9.37955Z"
          stroke="#1877F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1162_1796">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Facebook;
