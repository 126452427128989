const OptionsH = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clip-path="url(#clip0_1162_1810)">
        <circle cx="16" cy="16" r="15.5" stroke="#EBEBEB" />
        <path
          d="M16 17.6634C16.431 17.6634 16.8443 17.4878 17.149 17.1753C17.4538 16.8627 17.625 16.4388 17.625 15.9967C17.625 15.5547 17.4538 15.1308 17.149 14.8182C16.8443 14.5057 16.431 14.3301 16 14.3301C15.569 14.3301 15.1557 14.5057 14.851 14.8182C14.5462 15.1308 14.375 15.5547 14.375 15.9967C14.375 16.4388 14.5462 16.8627 14.851 17.1753C15.1557 17.4878 15.569 17.6634 16 17.6634ZM11.125 17.6634C11.556 17.6634 11.9693 17.4878 12.274 17.1753C12.5788 16.8627 12.75 16.4388 12.75 15.9967C12.75 15.5547 12.5788 15.1308 12.274 14.8182C11.9693 14.5057 11.556 14.3301 11.125 14.3301C10.694 14.3301 10.2807 14.5057 9.97595 14.8182C9.6712 15.1308 9.5 15.5547 9.5 15.9967C9.5 16.4388 9.6712 16.8627 9.97595 17.1753C10.2807 17.4878 10.694 17.6634 11.125 17.6634ZM20.875 17.6634C21.306 17.6634 21.7193 17.4878 22.024 17.1753C22.3288 16.8627 22.5 16.4388 22.5 15.9967C22.5 15.5547 22.3288 15.1308 22.024 14.8182C21.7193 14.5057 21.306 14.3301 20.875 14.3301C20.444 14.3301 20.0307 14.5057 19.726 14.8182C19.4212 15.1308 19.25 15.5547 19.25 15.9967C19.25 16.4388 19.4212 16.8627 19.726 17.1753C20.0307 17.4878 20.444 17.6634 20.875 17.6634Z"
          fill="#433E3E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1162_1810">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OptionsH;
