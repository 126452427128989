import React from "react";
import { NavLink } from "react-router-dom";
import Bgpolygon from "../Theme/Icons/Bgpolygon";
import BgpolygonMob from "../Theme/Icons/BgpolygonMob";
import Bgellips from "../Theme/Icons/Bgellips";
import BgellipsMb from "../Theme/Icons/BgellipsMb";
import PhotoCard from "./Components/PhotoCard";
import useResize from "../../utils/customHooks/use-resize";

export default function AnimeHeader() {
  const mob = useResize();

  return (
    <div class="pb-[500px] ml-[64px] max-[420px]:ml-[0px] max-[420px]:pb-[396px]">
      <div class="flex justify-between max-[420px]:text-center">
        <div class="flex column flex-col z-[2] relative max-[420px]:top-[17em]">
          <div class="mb-[24px] max-w-[874px] max-[420px]:w-[100%] max-[420px]:flex max-[420px]:justify-center ">
            <h1 class="w-[863px] max-[420px]:w-[340px] font-mainText text-white text-[48px] leading-[53px] max-[420px]:text-[24px] max-[420px]:leading-[32px]">
              Transform a photo of yourself or a loved one,&nbsp;
              <span class="font-mainText text-regular text-[48px] leading-[53px]  max-[420px]:text-[24px] max-[420px]:leading-[32px]">
                into stunning Anime art
              </span>
            </h1>
          </div>
          <div>
            <h2 class="mb-[64px] max-[420px]:mb-[34px] font-main text-white text-[24px]  leading-[29px]  max-[420px]:text-[14px] max-[420px]:leading-[20px]">
              Simply upload a photo, select a style and have a printed portrait
              delivered to you
            </h2>
          </div>
          <div class="flex items-center gap-[24px] max-[420px]:flex-col">
            <button
              class="btn-second text-[white] flex items-center justify-center  max-[420px]:text-[16px] max-[420px]:px-[20px]"
              onClick={() => {
                // dispatch(openModal(true));
              }}
            >
              Learn More
            </button>
            <NavLink to={"/create"}>
              <button
                class="btn text-[white] flex items-center justify-center max-[420px]:px-[18px] max-[420px]:text-[16px]"
                // onClick={() => {
                //   dispatch(openModal(true));
                // }}
              >
                Start to Create a Book
              </button>
            </NavLink>
          </div>
        </div>
        <div class="flex relative">
          <div>
            <div class="absolute top-[-11em] right-[0em] z-0">
              {mob ? <BgpolygonMob /> : <Bgpolygon />}
            </div>
            <div class="absolute top-[-8em] right-[0em] z-0 max-[420px]:right-[-3em] max-[420px]:top-[-12em]">
              {mob ? <BgellipsMb /> : <Bgellips />}
            </div>
            <div class="absolute top-[-2em] right-[0em] z-[1] w-[314px] h-[433px] max-[420px]:w-[153px]  max-[420px]:right-[5em]">
              <img
                src={require("../Theme/Img/Fotor_AI1.png")}
                alt="After"
                class="max-[420px]:rounded-[8px]"
              />
            </div>
            <div class="z-[2] absolute top-[17em] right-[14em] max-[420px]:top-[7em] max-[420px]:right-[12em]">
              {mob ? (
                <PhotoCard
                  width={"74px"}
                  height={"109px"}
                  image={"/images/before1.jpg\r"}
                />
              ) : (
                <PhotoCard
                  width={"153px"}
                  height={"224px"}
                  image={"/images/before1.jpg\r"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
