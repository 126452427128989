import React, { useEffect } from "react";
import ModalCreate from "./Components/Modal/ModalCreate/ModalCreate";
import { useSelector, useDispatch } from "react-redux";
import Footer from "./Components/Footer/Footer";
import AppRoutes from "./routes/AppRouters";
import Header from "./Components/Header/Header";
import BurgerModal from "./Components/Modal/BurgerModal";
import { setListBlog } from "./store/blog/blogSlice";
import blogAPI from "./utils/Api/blogAPI";
import ModalConfirm from "./Components/Modal/ModalConfirm";
import ModalPaySticker from "./Components/Modal/ModalPaySticker";
import PayPageMob from "./Components/StickersPage/Mob/PayPageMob";
import useResize from "./utils/customHooks/use-resize";

function App() {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.helpers.modal);
  const confirm = useSelector((state) => state.helpers.modalConfirm.open);
  const burger = useSelector((state) => state.helpers.burger);
  const touch = useSelector((state) => state.helpers.touch);
  const modalSticker = useSelector((state) => state.helpers.modalSticker);
  const page = useSelector((state) => state.helpers.page);
  const mob = useResize();

  const getAllBlogs = async () => {
    const response = await blogAPI.GetAllBlog();
    return dispatch(setListBlog(response.data));
  };

  useEffect(() => {
    getAllBlogs();
  }, [touch]);

  return (
    <div class="m-0 relative">
      <Header />
      {burger && <BurgerModal />}
      <AppRoutes />
      {confirm && <ModalConfirm />}
      {modal && <ModalCreate />}
      {page === "UploadImage" && modalSticker && !mob && <ModalPaySticker />}
      {page === "UploadImage" && modalSticker && mob && <PayPageMob />}
      <Footer />
    </div>
  );
}

export default App;
