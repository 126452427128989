const After = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="50"
      viewBox="0 0 22 50"
      fill="none"
    >
      <path
        d="M13.9282 1.99997C18.1097 8.69258 18.7761 15.5319 19.1964 18.3057C19.5745 20.8011 20.0542 23.7324 19.4484 26.2635C17.5429 34.2245 6.85386 29.6917 2.78725 26.5941C-0.429224 24.144 3.9629 17.7432 6.38823 16.016C8.42419 14.7581 11.4693 16.5317 12.8052 18.0164C13.7355 19.2108 14.8953 20.6244 15.4946 22.4403C16.1934 24.1411 16.1398 26.5601 16.2603 28.3973C16.467 31.5492 14.3383 34.7839 12.9267 37.0575C11.5152 39.3311 10.049 41.5206 8.04429 43.2784C6.52251 44.6229 4.93988 45.9652 3.37166 47.2532M3.37166 47.2532L3.99156 41.3394M3.37166 47.2532L9.22642 46.1459"
        stroke="#77636E"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default After;
