import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "../Pages/Main/Main";
import Create from "../Pages/Create/StartCreate";
import Blog from "../Pages/Blog/Blog";
import Product from "../Pages/Product/Product";
import AnimePortraits from "../Pages/AnimePortraits/AnimePortraits";
import PayPage from "../Pages/PayPage/PayPage";
import SuccessfullyPage from "../Components/Checkout/SuccessfullyPage";
import Login from "../Pages/Admin/Login";
import AdminPanel from "../Pages/Admin/AdminPanel";
import StickersPage from "../Pages/StickersPage/StickersPage";
import UploadImage from "../Pages/UploadImage/UploadImage";
import { RequireAuth, RequireLogin } from "../Components/hoc/RequireAuth";
import { RequireFill, RequirePay } from "../Components/hoc/RequireFillOrder";
import Page404 from "../Pages/Page404/Page404";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/create" element={<Create />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/post/:id" element={<Product />} />
      <Route path="/anime-portraits" element={<AnimePortraits />} />
      <Route path="/stickers-page" element={<StickersPage />} />
      <Route path="/stickers-page-upload" element={<UploadImage />} />
      <Route
        path="/checkout"
        element={
          <RequireFill>
            <PayPage />
          </RequireFill>
        }
      />
      <Route
        path="/payment-successfully"
        element={
          <RequirePay>
            <SuccessfullyPage />
          </RequirePay>
        }
      />
      <Route
        path="/Login"
        element={
          <RequireLogin>
            <Login />
          </RequireLogin>
        }
      />
      <Route
        path="/admin"
        element={
          <RequireAuth>
            <AdminPanel />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default AppRoutes;
