import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { PatchOrderPay } from "../../../utils/Api/orderApi";
import { useDispatch } from "react-redux";
import { addPay } from "../../../store/story/storySlice";


const CheckoutForm = ({sum}) => {
  const stripe = useStripe();
  const elements = useElements();
  const email = useSelector((state) => state.story.storyBook);
  const dispatch = useDispatch();

  const url = "http://localhost:3000";

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const fetchPay = async (data) => {
      try {
        await PatchOrderPay(email);
        dispatch(addPay(true));
      } catch (err) {
        console.log(err);
      }
    };

    fetchPay();
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        fetchPay,
        return_url: `${url}/payment-successfully`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      fetchPay();
      // dispatch()
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit} class="mt-[48px]">
      <PaymentElement onPaymentSuccess={handleSubmit} />
      <button class="btn mt-[48px] w-[100%] justify-center" disabled={!stripe}>
        Purchase ( £{sum.total},99)
      </button>
    </form>
  );
};

export default CheckoutForm;
