const uploadImageArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8.8752 2.9001L17.3002 11.3001C17.4002 11.4001 17.4709 11.5084 17.5122 11.6251C17.5542 11.7418 17.5752 11.8668 17.5752 12.0001C17.5752 12.1334 17.5542 12.2584 17.5122 12.3751C17.4709 12.4918 17.4002 12.6001 17.3002 12.7001L8.8752 21.1251C8.64186 21.3584 8.3502 21.4751 8.0002 21.4751C7.6502 21.4751 7.3502 21.3501 7.1002 21.1001C6.8502 20.8501 6.7252 20.5584 6.7252 20.2251C6.7252 19.8918 6.8502 19.6001 7.1002 19.3501L14.4502 12.0001L7.1002 4.6501C6.86686 4.41676 6.7502 4.12943 6.7502 3.7881C6.7502 3.4461 6.8752 3.1501 7.1252 2.9001C7.3752 2.6501 7.66686 2.5251 8.0002 2.5251C8.33353 2.5251 8.6252 2.6501 8.8752 2.9001Z"
        fill="#EBB268"
      />
    </svg>
  );
};

export default uploadImageArrowRight;
