import React from "react";
import { useParams } from "react-router-dom";
import Twitter from "../../Components/Theme/Icons/Twitter";
import Facebook from "../../Components/Theme/Icons/Facebook";
import Copylink from "../../Components/Theme/Icons/Copylink";
import OptionsH from "../../Components/Theme/Icons/OptionsH";
import { useSelector } from "react-redux";
import Card from "../Blog/Components/Card";

export default function Product() {
  const { id } = useParams();
  const blogList = useSelector((state) => state.blog.listBlog);
  const relatedIdList = useSelector((state) => state.blog.relatedArray);
  const product = blogList?.find((el) => el._id == id);

  const link = "http://localhost:3009";

  const relatedItems = () =>
    relatedIdList.map((i) => blogList?.find((el) => el._id === i));

  const excerptList = product?.content.split(" ").slice(0, 20).join(" ");

  return (
    <div class="pt-[64px] pb-[128px] px-[10%]">
      <div>
        <div>
          <div
            class="w-[100%] h-[500px] bg-gradient-to-r from-cardElFrom to-cardElTo flex items-start flex-col text-left relative rounded-[15px]"
            style={{
              backgroundImage: `url(${link}${product?.thumbnail})`,
            }}
          >
            <div class="absolute bottom-[32px]">
              <div class="px-[16px] pb-[16px]">
                <h1 class="font-mainText text-white text-[48px] leading-[56px]">
                  {product?.title}
                </h1>
              </div>
              <div class="px-[16px]">
                <p class="font-textSec text-[18px] leading-[26px] pb-[32px] text-white pr-[16px]">
                  {product?.date}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-[48px] pb-[32px]">
          <p class="font-textSec text-[14px] leading-[22px] text-black">
            {product?.content}
          </p>
        </div>
        <div class="pb-[64px]">
          <ul class="flex items-center gap-[8px]">
            <li class="text-[16px] text-[#999393] cursor-pointer">Share</li>
            <li class="cursor-pointer">
              <Twitter />
            </li>
            <li class="cursor-pointer">
              <Facebook />
            </li>
            <li class="cursor-pointer">
              <Copylink />
            </li>
            <li class="cursor-pointer">
              <OptionsH />
            </li>
          </ul>
        </div>
        <div class="flex flex-col">
          <div>
            <h2 class="text-[20px] text-[#3C182B] font-mainText pb-[16px]">
              Related
            </h2>
          </div>
          <div class="w-[100%] h-[1px] bg-[#C5C3C3] mb-[32px]"></div>
          <div class="flex gap-[32.5px] pb-[128px] justify-center">
            {relatedItems().map((el) => (
              <Card
                post={el}
                excerptList={excerptList}
                key={Math.random(0, 1)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
