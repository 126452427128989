import React, { useEffect } from "react";
import RightSide from "./RightSide";
import { setPage } from "../../store/helpers/helpersSlice";
import { useDispatch } from "react-redux";
import Payment from "../../Components/Checkout/Stripe/index";


export default function PayCard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage("payCard"));
  }, []);

  return (
    <div class="flex flex-col pb-[239px]">
      <div class="flex py-[32px]">
        <ul class="flex cursor-pointer">
          <li class="ml-[5px]">Storybook /</li>
          <li class="ml-[5px]">Customize Book /</li>
          <li class="ml-[5px]">Checkout /</li>
        </ul>
      </div>
      <h1 class="text-regular text-[48px] text-mainText font-normal pb-[48px]">
        Payment
      </h1>
      <div class="flex justify-between relative">
        <div class=" w-[100%]">
          <Payment />
        </div>
        <div class="h-[100%] w-[2px] bg-[#ECECEC] absolute left-[50%]"></div>
        <div class="text-left w-[100%] ml-[64px] ">
          <RightSide />
        </div>
      </div>
    </div>
  );
}
