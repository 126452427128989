import React from "react";
import { link } from "../../utils/Api/url";
import Regenerate from "../Theme/Icons/Regenerate";
import UploadImageArrowRight from "../Theme/Icons/UploadImageArrowRight";
import UploadImageArrowLeft from "../Theme/Icons/UploadImageArrowLeft";
import useUpload from "../../utils/customHooks/use-upload";

export default function UploadedImage({ photo }) {
  const page = "1/1";
	const { dropHandler, dragLeaveHandler, dragStartHandler, drag} = useUpload()

  return (
    <div class="h-[1024px] w-[100%]  max-[420px]:bg-none">
      <div class="flex flex-col justify-center items-center pt-[50px]">
        <div class="flex pb-[27px]">
          <h1 class="font-textBtn text-[#000000] text-[24px] font-bold max-[420px]:text-[14px] max-[420px]:max-w-[268px] max-[420px]:text-center">
            Upload Image to Create Stunning Sticker
          </h1>
        </div>
        <div class="bg-[#F7E5CE] "
				onDragStart={(e) => dragStartHandler(e)}
            onDragLeave={(e) => dragLeaveHandler(e)}
            onDragOver={(e) => dragStartHandler(e)}
            onDrop={(e) => dropHandler(e)}
				>
          <div class="my-[32px] mx-[32px] max-[420px]:mx-0 max-[420px]:my-0 bg-white border-2 border-dashed border-amber-300 shadow-md rounded-lg w-[838px] h-[438px] max-[420px]:max-w-[311px] max-[420px]:max-h-[189px] flex items-center justify-center">
            <div class="flex flex-col items-center justify-center">
              <div>
                <img
                  src={`${link}${photo}`}
                  alt="Uploaded Image"
                  class="w-[235px] h-[329px] rounded-[8px]"
                />
              </div>
							{drag && (
                    <div>
                      <h1 class="font-textBtn text-[14px] font-bold text-center text-[#767676]">
                        release the button to download
                      </h1>
                      <p class="font-textBtn text-[14px] text-center text-[#767676]">
                        jpg/png/jpeg. Max Size 5MB or 5000*500
                      </p>
                    </div>
                  )}
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between px-[10%] pt-[62px]">
        <div class="flex items-center">
          <p class="text-[#433E3E] text-[18px] font-textSec pr-[35px]">
            15 Stickers for you
          </p>
          <div class="flex items-center">
            <button class="btn px-[16px] py-[14px] mr-[7px]">
              Download All
            </button>
            <button class="btn-second px-[16px] py-[12px]">
              <Regenerate />
              Regenerate All
            </button>
          </div>
        </div>
        <div class="flex items-center gap-[19px]">
          <div class="cursor-pointer">
            <UploadImageArrowLeft />
          </div>
          <span>{page}</span>
          <div class="cursor-pointer">
            <UploadImageArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
}
