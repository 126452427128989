const UploadIco = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 12C7.71667 12 7.479 11.904 7.287 11.712C7.095 11.52 6.99934 11.2827 7 11V3.85L5.125 5.725C4.925 5.925 4.69167 6.025 4.425 6.025C4.15834 6.025 3.91667 5.91667 3.7 5.7C3.5 5.5 3.40434 5.26267 3.413 4.988C3.42167 4.71334 3.51733 4.484 3.7 4.3L7.3 0.700003C7.4 0.600003 7.50834 0.529336 7.625 0.488003C7.74167 0.446669 7.86667 0.42567 8 0.425003C8.13334 0.425003 8.25834 0.446003 8.375 0.488003C8.49167 0.530003 8.6 0.600669 8.7 0.700003L12.3 4.3C12.5 4.5 12.596 4.73767 12.588 5.013C12.58 5.28834 12.484 5.51734 12.3 5.7C12.1 5.9 11.8623 6.00434 11.587 6.013C11.3117 6.02167 11.0743 5.92567 10.875 5.725L9 3.85V11C9 11.2833 8.904 11.521 8.712 11.713C8.52 11.905 8.28267 12.0007 8 12ZM2 16C1.45 16 0.979002 15.804 0.587002 15.412C0.195002 15.02 -0.000664969 14.5493 1.69779e-06 14V12C1.69779e-06 11.7167 0.0960018 11.479 0.288002 11.287C0.480002 11.095 0.717335 10.9993 1 11C1.28333 11 1.521 11.096 1.713 11.288C1.905 11.48 2.00067 11.7173 2 12V14H14V12C14 11.7167 14.096 11.479 14.288 11.287C14.48 11.095 14.7173 10.9993 15 11C15.2833 11 15.521 11.096 15.713 11.288C15.905 11.48 16.0007 11.7173 16 12V14C16 14.55 15.804 15.021 15.412 15.413C15.02 15.805 14.5493 16.0007 14 16H2Z"
        fill="#0A0A0C"
      />
    </svg>
  );
};

export default UploadIco;
