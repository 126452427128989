import React from "react";
import { useDispatch } from "react-redux";
import { setModalSticker } from "../../store/helpers/helpersSlice";
import Payment from "../../Components/Checkout/Stripe/index";
import Cross from "../../Components/Theme/Icons/Cross";
import PolygonForModal from "../../Components/Theme/Icons/PolygonForModal";
import PolygonForModal2 from "../../Components/Theme/Icons/PolygonForModal2";

export default function ModalPaySticker() {
  const dispatch = useDispatch();

  //! need to setup sum & currency  in stripe
  const data = { total: 49 };

  return (
    <div
      class="bg-modal top-0 left-0 w-[100%] h-[100%] block fixed z-50"
      onClick={(e) => {
        dispatch(setModalSticker(false));
      }}
    >
      <div
        class="bg-[#FFFFFF] rounded-[8px]  fixed top-[14%] left-[22%] max-[420px]:w-[90%] max-[420px]:left-[5%] max-[420px]:top-[25%]"
        onClick={(e) => e.stopPropagation()}
      >
        <div class="flex flex-col items-center justify-center">
          <div class="text-left">
            <div class="flex justify-between pl-[32px]">
              <div class="mt-[32px] w-[408px] pr-[32px]">
                <h1 class="font-textBtn text-[24px] text-[#2D061B] text-left pb-[37px]">
                  Purchase your style, unlock your &nbsp;
                  <span class="text-[#EBB268]">stunning stickers</span>
                </h1>
                <div class="pb-[32px]">
                  <Payment data={data} />
                </div>
              </div>
              <div class="bg-[#0A0A0C] rounded-r-[8px] relative">
                <div class="absolute left-0 z-0">
                  <PolygonForModal />
                </div>
                <div class="absolute bottom-0 left-0 z-0">
                  <PolygonForModal2 />
                </div>
                <div
                  class="absolute w-[24px] h-[24px] bg-[#EBB268] rounded-[50%] right-[8px] top-[8px] cursor-pointer"
                  onClick={() => {
                    dispatch(setModalSticker(false));
                  }}
                >
                  <Cross />
                </div>
                <div class="flex flex-col pt-[63px] px-[41px] w-[428px]">
                  <h1 class="font-textBtn text-[24px] text-center text-[#FFFFFF] pb-[16px]">
                    Create Your Own Sticker With a &nbsp;
                    <span class="text-[#EBB268]">Caricature Style</span>
                  </h1>
                  <h2 class="font-textBtn text-[24px] text-center text-[#EBB268] pb-[32px]">
                    $4.99
                  </h2>
                </div>
                <div class="flex justify-center items-center pb-[52px]">
                  <div class="bg-gradient-to-b from-[rgba(0, 0, 0, 0.42)] via-transparent to-transparent rounded-tl-8 rounded-tr-8 h-[289px] w-[326px] relative">
                    <img
                      class="rounded-tl-[8px] rounded-tr-[8px]"
                      src={require("../Theme/Img/download1.png")}
                      alt="DownloadImage"
                    />
                    <img
                      class="absolute bottom-[16px] left-[16px]"
                      src={require("../Theme/Img/before12.png")}
                      alt="DownloadImage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
