import React from "react";

const Error404Page = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-[#2D061B]">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-[#433E3E] font-main mb-4">
          404 Error
        </h1>
        <p className="text-2xl text-[#433E3E] font-second">Page not found</p>
      </div>
    </div>
  );
};

export default Error404Page;
