const Twitter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clip-path="url(#clip0_1162_1789)">
        <circle cx="16" cy="16" r="15.5" stroke="#EBEBEB" />
        <path
          d="M12.0861 21.1486C10.78 21.119 9.58186 20.1913 8.96905 18.748C9.40171 18.7534 9.83399 18.6858 10.2528 18.5463L11.8939 17.9996L10.2142 17.5864C8.80763 17.2404 7.62251 15.8186 7.3583 13.9375C7.77022 14.0994 8.20257 14.1919 8.64302 14.2088L10.1315 14.2659L8.97893 13.3223C8.00687 12.5264 7.3368 11.1371 7.3368 9.5258C7.3368 9.00957 7.40586 8.51489 7.53221 8.05482C9.60831 10.7654 12.5291 12.5472 15.819 12.7495L16.461 12.7889L16.3409 12.157C16.2769 11.8205 16.2434 11.4682 16.2434 11.1049C16.2434 8.50601 17.9469 6.5791 19.8468 6.5791C20.8446 6.5791 21.7735 7.09416 22.445 7.97041L22.6429 8.22863L22.9591 8.15233C23.4565 8.03235 23.9423 7.86055 24.4115 7.63994C24.1208 8.12064 23.7573 8.52326 23.3474 8.82419L23.7152 9.72204C24.0482 9.67363 24.3778 9.60212 24.7024 9.50816C24.371 9.93305 24.009 10.3197 23.621 10.6632L23.4441 10.8199L23.4528 11.056C23.4606 11.2652 23.4644 11.4758 23.4644 11.6878C23.4644 14.9147 22.4594 18.3851 20.5516 21.0363C18.6509 23.6775 15.8721 25.4878 12.2898 25.4878C10.6565 25.4878 9.10235 25.0515 7.69742 24.2649C9.48378 24.0896 11.1102 23.2674 12.421 22.0092L13.2892 21.1759L12.0861 21.1486Z"
          stroke="#55ACEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_1162_1789">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Twitter
