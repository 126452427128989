import React, {useEffect} from "react";
import LeftSide from "./LeftSide";
import { useDispatch, useSelector } from "react-redux";
import RightSide from "./RightSide";
import { setPage } from "../../store/helpers/helpersSlice";

export default function Checkout() {
  const dispatch = useDispatch();
  const step = useSelector((state) => state.checkout.step);

	  useEffect(() => {
    dispatch(setPage("checkout"));
  }, []);

  return (
    <div class="flex flex-col pb-[239px]">
      <div class="flex py-[32px]">
        <ul class="flex cursor-pointer">
          <li class="ml-[5px]">Storybook /</li>
          <li class="ml-[5px]">Customize Book /</li>
          <li class="ml-[5px]">Checkout /</li>
        </ul>
      </div>
      <h1 class="text-regular text-[48px] text-mainText font-normal pb-[48px]">
        Checkout
      </h1>
      <div class="flex justify-between relative">
        <div class=" w-[100%]">
          <p class="text-mainText text-[#C5C3C3] text-[20px] font-normal">
            Personal Details
          </p>
          <LeftSide />
        </div>
        <div class="h-[100%] w-[2px] bg-[#ECECEC] absolute left-[50%]"></div>
        <div class="text-left w-[100%] ml-[64px]">
          <p class="text-mainText text-[#C5C3C3] text-[20px] font-normal">
            Order Summary
          </p>
          <div>
            <RightSide />
          </div>
        </div>
      </div>
    </div>
  );
}
