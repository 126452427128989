const ArrowNext = ({ empty = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <g clip-path="url(#clip0_442_369)">
        <circle
          cx="18"
          cy="18"
          r="18"
          transform="rotate(-180 18 18)"
          fill="#EBB268"
          fill-opacity="0.8"
        />
        <path
          d="M14.875 8.89961L23.3 17.2996C23.4 17.3996 23.4707 17.5079 23.512 17.6246C23.554 17.7413 23.575 17.8663 23.575 17.9996C23.575 18.1329 23.554 18.2579 23.512 18.3746C23.4707 18.4913 23.4 18.5996 23.3 18.6996L14.875 27.1246C14.6417 27.3579 14.35 27.4746 14 27.4746C13.65 27.4746 13.35 27.3496 13.1 27.0996C12.85 26.8496 12.725 26.5579 12.725 26.2246C12.725 25.8913 12.85 25.5996 13.1 25.3496L20.45 17.9996L13.1 10.6496C12.8667 10.4163 12.75 10.1289 12.75 9.78761C12.75 9.44561 12.875 9.14961 13.125 8.89961C13.375 8.64961 13.6667 8.52461 14 8.52461C14.3333 8.52461 14.625 8.64961 14.875 8.89961Z"
          fill={empty ? "#6F6F6F" : "#2D061B"}
        />
      </g>
      <defs>
        <clipPath id="clip0_442_369">
          <rect
            width="36"
            height="36"
            fill="white"
            transform="matrix(-1 0 0 -1 36 36)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowNext;
