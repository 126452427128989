import React from "react";
import ArrowBackMob from "../../Theme/Icons/ArrowBackMobile";
import { useNavigate } from "react-router-dom";

export default function NavUploadImg({ nameHead = "Caricature Style" }) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div class="flex items-center justify-center px-[64px] max-[420px]:px-[5%] pb-[33px] pt-[27px] w-[100%] bg-main-color">
      <div class="items-center flex">
        <div class="absolute left-[16px] cursor-pointer" onClick={handleGoBack}>
          <ArrowBackMob />
        </div>
        <div>
          <h1 class="text-[#FFFFFF] text-[18px] font-mainText">{nameHead}</h1>
        </div>
      </div>
    </div>
  );
}
