import React, { useEffect } from "react";
import AnimeHeader from "../../Components/AnimePortraits/AnimeHeader";
import MainSection from "../../Components/AnimePortraits/MainSection";
import HowToConvert from "../../Components/AnimePortraits/HowToConvert";
import WorkGalery from "../../Components/AnimePortraits/WorkGalery";
import Testimonial from "../../Components/AnimePortraits/Testimonial";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/helpers/helpersSlice";
import UseSeo from "../../utils/customHooks/use-seo";

export default function AnimePortraits() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPage("landing"));
  }, []);

  return (
    <>
      <UseSeo
        title={"Convert Your Photo into Anime Art"}
        description={
          "Transform a photo of yourself or a loved one, into stunning Anime art"
        }
        keywords={"printed portrait, Convert Photo, Anime Art"}
      />
      <div class="bg-main-color">
        <div class="bg-bg-main relative  overflow-hidden pt-[176px]">
          <AnimeHeader />
        </div>
        <div class="max-[420px]:px-[10%]">
          <MainSection />
        </div>
        <div class="max-[420px]:px-[10%]">
          <HowToConvert />
        </div>
        <div class="overflow-hidden max-[420px]:px-[10%]">
          <WorkGalery />
        </div>
        <div>
          <Testimonial />
        </div>
      </div>
    </>
  );
}
