import React from "react";
import Checkout from "../../Components/Checkout/Checkout";
import PayCard from "../../Components/Checkout/PayCard";
import { useSelector } from "react-redux";

export default function PayPage() {
  const step = useSelector((state) => state.checkout.step);
	
  if (step === 1) {
    return (
      <div class="ml-[5%]">
        <Checkout />
      </div>
    );
  }
  if (step === 2) {
    return (
      <div class="ml-[5%]">
        <PayCard />
      </div>
    );
  }
}
