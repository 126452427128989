const UniqStickers = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M21.3333 36.5833C21.3333 36.9976 21.6691 37.3333 22.0833 37.3333H25.9167C26.3309 37.3333 26.6667 36.9976 26.6667 36.5833V32C26.6667 31.2445 26.9227 30.6107 27.4347 30.0987C27.9467 29.5867 28.5796 29.3316 29.3333 29.3333H34.6667V32.856C34.6667 33.5242 35.4745 33.8588 35.947 33.3863L42.1363 27.197C42.4292 26.9041 42.4292 26.4292 42.1363 26.1363L35.947 19.947C35.4745 19.4745 34.6667 19.8092 34.6667 20.4773V24H29.3333C27.1111 24 25.2222 24.7778 23.6667 26.3333C22.1111 27.8889 21.3333 29.7778 21.3333 32V36.5833ZM5.33333 56C4.57778 56 3.944 55.744 3.432 55.232C2.92 54.72 2.66489 54.0871 2.66667 53.3333C2.66667 52.5778 2.92267 51.944 3.43467 51.432C3.94667 50.92 4.57955 50.6649 5.33333 50.6667H58.6667C59.4222 50.6667 60.056 50.9227 60.568 51.4347C61.08 51.9467 61.3351 52.5796 61.3333 53.3333C61.3333 54.0889 61.0773 54.7227 60.5653 55.2347C60.0533 55.7467 59.4204 56.0018 58.6667 56H5.33333ZM10.6667 48C9.2 48 7.944 47.4773 6.89867 46.432C5.85333 45.3867 5.33155 44.1316 5.33333 42.6667V13.3333C5.33333 11.8667 5.856 10.6107 6.90133 9.56534C7.94667 8.52 9.20178 7.99823 10.6667 8H53.3333C54.8 8 56.056 8.52267 57.1013 9.568C58.1467 10.6133 58.6684 11.8684 58.6667 13.3333V42.6667C58.6667 44.1333 58.144 45.3893 57.0987 46.4347C56.0533 47.48 54.7982 48.0018 53.3333 48H10.6667Z"
        fill="#2D061B"
      />
      <path
        d="M21.3333 36.5833C21.3333 36.9976 21.6691 37.3333 22.0833 37.3333H25.9167C26.3309 37.3333 26.6667 36.9976 26.6667 36.5833V32C26.6667 31.2445 26.9227 30.6107 27.4347 30.0987C27.9467 29.5867 28.5796 29.3316 29.3333 29.3333H34.6667V32.856C34.6667 33.5242 35.4745 33.8588 35.947 33.3863L42.1363 27.197C42.4292 26.9041 42.4292 26.4292 42.1363 26.1363L35.947 19.947C35.4745 19.4745 34.6667 19.8092 34.6667 20.4773V24H29.3333C27.1111 24 25.2222 24.7778 23.6667 26.3333C22.1111 27.8889 21.3333 29.7778 21.3333 32V36.5833Z"
        fill="#EBB268"
      />
    </svg>
  );
};

export default UniqStickers;
