import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { snackActions } from "./useSnackBarUtils"
import { setListPhoto } from "../../store/stickers/stickersSlice";
import { uploadStickers } from "../../utils/Api/uploadApi";

const useUpload = () => {
	const [drag, setDrag] = useState(false);
	const dispatch = useDispatch();

	const handleUpload = async (data) => {
    if (!data) {
      snackActions.warning("Please select a file");
      return;
    } else {
      try {
        const formData = new FormData();
        for (let i = 0; i < data.length; i++) {
          formData.append("photos", data[i]);
        }
        const response = await uploadStickers(formData);
        const fileUrls = response.data;
        dispatch(setListPhoto({ thumbnail: fileUrls }));
        if (response.status === 200) {
          snackActions.success("You have successfully uploaded the image");
        }
      } catch (error) {
        snackActions.error(error.message);
      }
    }
  };


	const dragStartHandler = (e) => {
    e.preventDefault();
    setDrag(true);
  };

  const dragLeaveHandler = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const dropHandler = (e) => {
    e.preventDefault();
    handleUpload(e.dataTransfer.files);
    setDrag(false);
  };

	return {handleUpload, dropHandler, dragLeaveHandler, dragStartHandler, drag}

 
};

export default useUpload;
