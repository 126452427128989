const PrivacyStickers = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M32 45.3333C32.7556 45.3333 33.3893 45.0773 33.9013 44.5653C34.4133 44.0533 34.6684 43.4204 34.6667 42.6667V32C34.6667 31.2444 34.4107 30.6107 33.8987 30.0987C33.3867 29.5867 32.7538 29.3316 32 29.3333C31.2444 29.3333 30.6107 29.5893 30.0987 30.1013C29.5867 30.6133 29.3316 31.2462 29.3333 32V42.6667C29.3333 43.4222 29.5893 44.056 30.1013 44.568C30.6133 45.08 31.2462 45.3351 32 45.3333ZM32 24C32.7556 24 33.3893 23.744 33.9013 23.232C34.4133 22.72 34.6684 22.0871 34.6667 21.3333C34.6667 20.5778 34.4107 19.944 33.8987 19.432C33.3867 18.92 32.7538 18.6649 32 18.6667C31.2444 18.6667 30.6107 18.9227 30.0987 19.4347C29.5867 19.9467 29.3316 20.5796 29.3333 21.3333C29.3333 22.0889 29.5893 22.7227 30.1013 23.2347C30.6133 23.7467 31.2462 24.0018 32 24ZM32 58.4667H31.3333C31.1111 58.4667 30.9111 58.4222 30.7333 58.3333C24.9111 56.5111 20.1111 52.8996 16.3333 47.4987C12.5556 42.0978 10.6667 36.1316 10.6667 29.6V17C10.6667 15.8889 10.9893 14.8889 11.6347 14C12.28 13.1111 13.1129 12.4667 14.1333 12.0667L30.1333 6.06667C30.7556 5.84445 31.3778 5.73334 32 5.73334C32.6222 5.73334 33.2444 5.84445 33.8667 6.06667L49.8667 12.0667C50.8889 12.4667 51.7227 13.1111 52.368 14C53.0133 14.8889 53.3351 15.8889 53.3333 17V29.6C53.3333 36.1333 51.4444 42.1004 47.6667 47.5013C43.8889 52.9022 39.0889 56.5129 33.2667 58.3333C33.0444 58.4222 32.6222 58.4667 32 58.4667Z"
        fill="#2D061B"
      />
      <path
        d="M32 45.3333C32.7556 45.3333 33.3893 45.0773 33.9013 44.5653C34.4133 44.0533 34.6684 43.4204 34.6667 42.6667V32C34.6667 31.2444 34.4107 30.6107 33.8987 30.0987C33.3867 29.5867 32.7538 29.3316 32 29.3333C31.2444 29.3333 30.6107 29.5893 30.0987 30.1013C29.5867 30.6133 29.3316 31.2462 29.3333 32V42.6667C29.3333 43.4222 29.5893 44.056 30.1013 44.568C30.6133 45.08 31.2462 45.3351 32 45.3333Z"
        fill="#EBB268"
      />
      <path
        d="M32 24C32.7556 24 33.3893 23.744 33.9013 23.232C34.4133 22.72 34.6684 22.0871 34.6667 21.3333C34.6667 20.5778 34.4107 19.944 33.8987 19.432C33.3867 18.92 32.7538 18.6649 32 18.6667C31.2444 18.6667 30.6107 18.9227 30.0987 19.4347C29.5867 19.9467 29.3316 20.5796 29.3333 21.3333C29.3333 22.0889 29.5893 22.7227 30.1013 23.2347C30.6133 23.7467 31.2462 24.0018 32 24Z"
        fill="#EBB268"
      />
    </svg>
  );
};

export default PrivacyStickers;
