const QuestionIco = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="8" fill="#C5C3C3" />
      <path
        d="M8.5332 9.39258H7.03906C7.04297 9.04102 7.07031 8.73828 7.12109 8.48438C7.17578 8.22656 7.26758 7.99414 7.39648 7.78711C7.5293 7.58008 7.70508 7.375 7.92383 7.17188C8.10742 7.00781 8.26758 6.85156 8.4043 6.70312C8.54102 6.55469 8.64844 6.40234 8.72656 6.24609C8.80469 6.08594 8.84375 5.9082 8.84375 5.71289C8.84375 5.48633 8.80859 5.29883 8.73828 5.15039C8.67188 4.99805 8.57031 4.88281 8.43359 4.80469C8.30078 4.72656 8.13281 4.6875 7.92969 4.6875C7.76172 4.6875 7.60547 4.72461 7.46094 4.79883C7.31641 4.86914 7.19727 4.97852 7.10352 5.12695C7.01367 5.27539 6.9668 5.4707 6.96289 5.71289H5.26367C5.27539 5.17773 5.39844 4.73633 5.63281 4.38867C5.87109 4.03711 6.18945 3.77734 6.58789 3.60938C6.98633 3.4375 7.43359 3.35156 7.92969 3.35156C8.47656 3.35156 8.94531 3.44141 9.33594 3.62109C9.72656 3.79688 10.0254 4.05664 10.2324 4.40039C10.4395 4.74023 10.543 5.1543 10.543 5.64258C10.543 5.98242 10.4766 6.28516 10.3438 6.55078C10.2109 6.8125 10.0371 7.05664 9.82227 7.2832C9.60742 7.50977 9.37109 7.74414 9.11328 7.98633C8.89062 8.18555 8.73828 8.39453 8.65625 8.61328C8.57812 8.83203 8.53711 9.0918 8.5332 9.39258ZM6.86328 11.2031C6.86328 10.9531 6.94922 10.7461 7.12109 10.582C7.29297 10.4141 7.52344 10.3301 7.8125 10.3301C8.09766 10.3301 8.32617 10.4141 8.49805 10.582C8.67383 10.7461 8.76172 10.9531 8.76172 11.2031C8.76172 11.4453 8.67383 11.6504 8.49805 11.8184C8.32617 11.9863 8.09766 12.0703 7.8125 12.0703C7.52344 12.0703 7.29297 11.9863 7.12109 11.8184C6.94922 11.6504 6.86328 11.4453 6.86328 11.2031Z"
        fill="#999393"
      />
    </svg>
  );
};

export default QuestionIco;
