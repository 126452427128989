const uploadImageArrowLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.1248 21.0999L6.6998 12.6999C6.5998 12.5999 6.52914 12.4916 6.4878 12.3749C6.4458 12.2582 6.4248 12.1332 6.4248 11.9999C6.4248 11.8666 6.4458 11.7416 6.4878 11.6249C6.52914 11.5082 6.5998 11.3999 6.6998 11.2999L15.1248 2.8749C15.3581 2.64157 15.6498 2.5249 15.9998 2.5249C16.3498 2.5249 16.6498 2.6499 16.8998 2.8999C17.1498 3.1499 17.2748 3.44157 17.2748 3.7749C17.2748 4.10824 17.1498 4.3999 16.8998 4.6499L9.5498 11.9999L16.8998 19.3499C17.1331 19.5832 17.2498 19.8706 17.2498 20.2119C17.2498 20.5539 17.1248 20.8499 16.8748 21.0999C16.6248 21.3499 16.3331 21.4749 15.9998 21.4749C15.6665 21.4749 15.3748 21.3499 15.1248 21.0999Z"
        fill="#D9D6D6"
      />
    </svg>
  );
};

export default uploadImageArrowLeft