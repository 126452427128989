const infoUploadImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g id="material-symbols:info-rounded">
        <path
          id="Vector"
          d="M6 8.5C6.14167 8.5 6.2605 8.452 6.3565 8.356C6.4525 8.26 6.50033 8.14133 6.5 8V6C6.5 5.85833 6.452 5.7395 6.356 5.6435C6.26 5.5475 6.14133 5.49967 6 5.5C5.85833 5.5 5.7395 5.548 5.6435 5.644C5.5475 5.74 5.49967 5.85867 5.5 6V8C5.5 8.14167 5.548 8.2605 5.644 8.3565C5.74 8.4525 5.85867 8.50033 6 8.5ZM6 4.5C6.14167 4.5 6.2605 4.452 6.3565 4.356C6.4525 4.26 6.50033 4.14133 6.5 4C6.5 3.85833 6.452 3.7395 6.356 3.6435C6.26 3.5475 6.14133 3.49967 6 3.5C5.85833 3.5 5.7395 3.548 5.6435 3.644C5.5475 3.74 5.49967 3.85867 5.5 4C5.5 4.14167 5.548 4.2605 5.644 4.3565C5.74 4.4525 5.85867 4.50033 6 4.5ZM6 11C5.30833 11 4.65833 10.8687 4.05 10.606C3.44167 10.3433 2.9125 9.98717 2.4625 9.5375C2.0125 9.0875 1.65633 8.55833 1.394 7.95C1.13167 7.34167 1.00033 6.69167 1 6C1 5.30833 1.13133 4.65833 1.394 4.05C1.65667 3.44167 2.01283 2.9125 2.4625 2.4625C2.9125 2.0125 3.44167 1.65633 4.05 1.394C4.65833 1.13167 5.30833 1.00033 6 1C6.69167 1 7.34167 1.13133 7.95 1.394C8.55833 1.65667 9.0875 2.01283 9.5375 2.4625C9.9875 2.9125 10.3438 3.44167 10.6065 4.05C10.8692 4.65833 11.0003 5.30833 11 6C11 6.69167 10.8687 7.34167 10.606 7.95C10.3433 8.55833 9.98717 9.0875 9.5375 9.5375C9.0875 9.9875 8.55833 10.3438 7.95 10.6065C7.34167 10.8692 6.69167 11.0003 6 11Z"
          fill="#BA2323"
        />
      </g>
    </svg>
  );
};

export default infoUploadImage
