import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../../store/helpers/helpersSlice";
import UploadedImage from "../../Components/StickersPage/UploadImage";
import BeeforeUploadImage from "../../Components/StickersPage/BeforeUploadImage";
import useResize from "../../utils/customHooks/use-resize";
import UploadImageMob from "../../Components/StickersPage/Mob/UploadImageMob";

export default function UploadImage() {
  const photoCard = useSelector((state) => state.stickers.listPhoto);
  const dispatch = useDispatch();
  const mob = useResize();

  useEffect(() => {
    dispatch(setPage("UploadImage"));
  }, []);

  if (photoCard?.thumbnail.length) {
    return mob ? (
      <UploadImageMob photo={photoCard?.thumbnail[0]} />
    ) : (
      <UploadedImage photo={photoCard?.thumbnail[0]} />
    );
  } else {
    return <BeeforeUploadImage />;
  }
}
