import React from "react";
import { NavLink } from "react-router-dom";

//! HERE NEED INSERT LINK TO APPSTORE & GOOGLE PLAY

export default function Download() {
  return (
    <div class="h-[190px] bg-[#212121] pb-[290px]">
      <div class=" flex flex-col justify-center items-center">
        <div class="flex flex-col justify-center items-center pt-[24px] pb-[16px]">
          <h1 class="font-textBtn text-[24px] text-white">
            Create your own sticker
          </h1>
          <p class="font-textBtn text-[12px] text-[#767676]">
            Start and try it on
          </p>
        </div>
        <div class="flex items-center justify-center gap-[16px]">
          <div class="cursor-pointer">
            <NavLink to={"/stickers-page-upload"}>
              <img src={require("../Theme/Img/download-appstore.png")} />
            </NavLink>
          </div>
          <div class="cursor-pointer">
            <NavLink to={"/stickers-page-upload"}>
              <img src={require("../Theme/Img/download-gplay.png")} />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
