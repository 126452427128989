import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listPhoto: null,
};

const stickersSlice = createSlice({
  name: "Stickers",
  initialState,
  reducers: {
    setListPhoto(state, action) {
      state.listPhoto = action.payload;
    },
  },
});

export const { setListPhoto } = stickersSlice.actions;

export default stickersSlice.reducer;
