import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setModalStickerMobile } from "../../../store/helpers/helpersSlice";

export default function CaricatureStyle() {
  const dispatch = useDispatch();

  return (
    <div class="w-[100%]">
      <div>
        <img
          src={require("../../Theme/Img/mobile/Frame6Mob.png")}
          alt="CaricatureImgHead"
          style={{
            width: "100%",
          }}
        />
      </div>
      <div class="flex flex-col pt-[34px] pb-[54px]">
        <div class="flex flex-col justify-center items-center pb-[24px]">
          <h1 class="text-textBtn font-[600] text-[32px] text-[#2D061B] mb-[4px]">
            $4,99
          </h1>
          <p class="text-textBtn  font-medium text-[12px] leading-6 text-[#767676]">
            Create your own sticker with a Caricature Style
          </p>
        </div>
        <div class="flex flex-col items-start pb-[54px] justify-start px-[16px]">
          <p class="text-textBtn  font-medium text-[12px] leading-6 text-[#767676]">
            What you will get:
          </p>
          <ul class="list-disc ml-[18px]">
            <li class="text-textBtn  font-[400] text-[14px] leading-6 text-[#212121]">
              You will be able to upload images up to 3 times
            </li>
            <li class="text-textBtn  font-[400] text-[14px] leading-6 text-[#212121]">
              You will get 3 times the regeneration response for each uploaded
              image.
            </li>
            <li class="text-textBtn  font-[400] text-[14px] leading-6 text-[#212121]">
              Total you will get 30 stickers with 10 each in 1 generate process.
            </li>
            <li class="text-textBtn  font-[400] text-[14px] leading-6 text-[#212121]">
              You can download all the stickers.
            </li>
          </ul>
          <p class="pt-[16px] text-textBtn  font-medium text-[12px] leading-6 text-[#767676]">
            Discover the joy of self-expression with MyStickerPal, a
            one-of-a-kind sticker generator app crafted to bring your favorite
            moments and expressions to life in the most personal way.
          </p>
        </div>
        <div
          class="pb-[45px] px-[5%]"
          onClick={() => {
            dispatch(setModalStickerMobile(false));
          }}
        >
          <NavLink to={"/stickers-page-upload"}>
            <button class="btn w-[100%] items-center justify-center">
              Purchase Style
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
