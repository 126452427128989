const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.3498 6.3137C12.5507 6.11284 12.8232 6 13.1073 6C13.3914 6 13.6639 6.11284 13.8648 6.3137L18.6863 11.1352C18.8872 11.3361 19 11.6086 19 11.8927C19 12.1768 18.8872 12.4493 18.6863 12.6502L13.8648 17.4717C13.6627 17.6669 13.3921 17.7749 13.1111 17.7725C12.8302 17.77 12.5615 17.6573 12.3628 17.4587C12.1642 17.26 12.0515 16.9913 12.049 16.7104C12.0466 16.4294 12.1546 16.1588 12.3498 15.9567L15.2502 12.9642H5.07144C4.78728 12.9642 4.51475 12.8513 4.31382 12.6503C4.11288 12.4494 4 12.1769 4 11.8927C4 11.6086 4.11288 11.336 4.31382 11.1351C4.51475 10.9342 4.78728 10.8213 5.07144 10.8213H15.2502L12.3498 7.82873C12.1489 7.6278 12.0361 7.35532 12.0361 7.07122C12.0361 6.78711 12.1489 6.51463 12.3498 6.3137Z"
        fill="#EBB268"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#EBB268" />
    </svg>
  );
};

export default ArrowRight;
