const Before = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="50"
      viewBox="0 0 22 50"
      fill="none"
    >
      <path
        d="M7.51782 1.99997C3.33637 8.69258 2.66996 15.5319 2.24968 18.3057C1.87156 20.8011 1.39183 23.7324 1.99768 26.2635C3.90317 34.2245 14.5922 29.6917 18.6588 26.5941C21.8753 24.144 17.4831 17.7432 15.0578 16.016C13.0219 14.7581 9.97675 16.5317 8.64089 18.0164C7.71055 19.2108 6.55076 20.6244 5.95143 22.4403C5.25268 24.1411 5.30622 26.5601 5.18572 28.3973C4.97901 31.5492 7.10774 34.7839 8.5193 37.0575C9.93085 39.3311 11.397 41.5206 13.4018 43.2784C14.9235 44.6229 16.5062 45.9652 18.0744 47.2532M18.0744 47.2532L17.4545 41.3394M18.0744 47.2532L12.2196 46.1459"
        stroke="#77636E"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Before
