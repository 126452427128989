import React from "react";
import ArrowRight from "../Theme/Icons/ArrowRight";
import Ellipse1 from "./Theme/Ellipse1";
import Ellipse3 from "./Theme/Ellipse3";
import Ellipse2 from "./Theme/Ellipse2";
import { NavLink } from "react-router-dom";

export default function Header() {
  return (
    <div class=" bg-[#0A0A0C] shadow-lg relative overflow-hidden h-[576px]">
      <div class="flex justify-between px-[64px]">
        <div class="pt-[114px]">
          <div class="py-[32px] w-[699px]">
            <h1 class="text-textBtn font-medium text-4xl leading-9 text-white pb-[16px]">
              Your Personalized Sticker Generator – Made Just for You!
            </h1>
            <p class="text-textBtn  font-medium text-base leading-6 text-gray-600">
              Discover the joy of self-expression with MyStickerPal, a
              one-of-a-kind sticker generator app crafted to bring your favorite
              moments and expressions to life in the most personal way.
            </p>
          </div>
          <NavLink to={"/stickers-page-upload"}>
            <div
              class="flex cursor-pointer z-50 absolute mt-[48px]"
              onClick={() => {
                console.log("aaaa");
              }}
            >
              <h2 class="font-poppins font-medium text-base leading-6 text-orange-300 mr-[12px]">
                Let’s Start to Create Sticker
              </h2>
              <ArrowRight />
            </div>
          </NavLink>
          <div class="absolute bottom-0 left-0 z-0">
            <Ellipse2 />
          </div>
          <div class="absolute bottom-0 z-0">
            <Ellipse3 />
          </div>
        </div>
        <div class="flex gap-[37px]">
          <div class="absolute right-[0]">
            <Ellipse1 />
          </div>
          <div class="absolute right-[348px] z-20">
            <div class="mb-[32px]">
              <img
                class="mb-[16px]"
                src={require("../Theme/Img/Sticker1.png")}
                alt="Sticker-head"
              />
              <img
                class="mb-[16px]"
                src={require("../Theme/Img/Sticker2.png")}
                alt="Sticker-head"
              />
              <img
                class="mb-[16px]"
                src={require("../Theme/Img/Sticker3.png")}
                alt="Sticker-head"
              />
            </div>
          </div>
          <div class="absolute right-[143px] z-20">
            <div class="mb-[32px]">
              <img
                class="mb-[16px]"
                src={require("../Theme/Img/Sticker1.png")}
                alt="Sticker-head"
              />
              <img
                class="mb-[16px]"
                src={require("../Theme/Img/Sticker2.png")}
                alt="Sticker-head"
              />
              <img
                class="mb-[16px]"
                src={require("../Theme/Img/Sticker3.png")}
                alt="Sticker-head"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
