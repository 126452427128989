import React from "react";
import MainEllips from "../Theme/Icons/MainBgEllips";
import MainPoligon from "../Theme/Icons/MainBgPoligon";
import Before from "../Theme/Icons/Before";
import After from "../Theme/Icons/After";

export default function MainSection() {
  return (
    <div class="h-[1000px] max-[420px]:h-[700px] relative">
      <div class="z-20">
        <div class="flex text-center items-center flex-col">
          <h1 class="flex font-mainText pb-[43px] text-regular text-[32px] max-[420px]:pb-[22px] max-[420px]:mx-[10%]  leading-[35px] max-[420px]:text-[24px] max-[420px]:leading-[32px]">
            Convert Your Photo into Anime Art
          </h1>
          <h2 class="z-20 mb-[64px] max-w-[832px] max-[420px]:mb-[34px] font-main text-white text-[16px]  leading-[22px]  max-[420px]:text-[14px] max-[420px]:leading-[20px]">
            Our artist will use a portrait of yourself or a loved one to create
            a custom portrait based on the photo you provide. It is entirely
            hand designed and custom made just for you. There aren’t any
            surprises either! You get to look over and approve the design before
            we send it out to you. If you’re stuck about what to order for a
            birthday or holiday gift—our custom art is the perfect solution. Who
            doesn’t love a portrait of themselves—especially one that is as
            fresh and original as our bespoke anime portraits?
          </h2>
        </div>
      </div>
      <div class="relative">
        <div class="w-[100%] flex justify-center">
          <div class="flex text-regular text-[24px] leading-[22px] text-textSec w-[400px] justify-between text-center items-center ">
            <p>Before</p>
            <p>After</p>
          </div>
        </div>
        <div class="w-[100%] flex justify-center pt-[12px] overflow-hidden">
          <div class="flex justify-between items-center w-[420px]">
            <Before />
            <After />
          </div>
        </div>
        <div class="absolute top-[-8em] left-[0em] z-0 max-[420px]:left-[-36em] max-[420px]:top-[-22em]">
          <MainPoligon />
        </div>
        <div class="absolute top-[-8em] z-0 flex justify-center w-[100%] max-[420px]:top-[-15em]">
          <MainEllips />
        </div>
        <div class="flex relative justify-center w-[100%]">
          <div class="relative w-[832px]">
            <div class="z-[2] absolute left-[10%] max-[420px]:w-[142px]">
              <img
                src={require("../Theme/Img/beforeBig.png")}
                alt="before-big"
              />
            </div>
            <div class="absolute z-[3] right-[10%] max-[420px]:w-[142px]">
              <img src={require("../Theme/Img/afterBig.png")} alt="after-big" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
